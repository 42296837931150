/* istanbul ignore file */

import React, { Suspense, lazy } from "react"
import { Routes, Route } from "react-router-dom"
import SignIn from "screens/SignIn"
import ErrorPage from "screens/ErrorScreen"
import Help from "screens/Help"

const LoginForm = lazy(() => import("screens/SignIn/LoginForm"))
const Download = lazy(() => import("screens/SignIn/Download"))
const Settings = lazy(() => import("screens/Settings"))
const SettingsNav = lazy(() => import("screens/Settings/components/Nav"))
const SettingsMyInfo = lazy(() => import("screens/Settings/MyInfo"))
const SettingsDeviceTest = lazy(() => import("screens/Settings/DeviceTest"))
const SettingsFeedback = lazy(() => import("screens/Settings/Feedback"))
const Home = lazy(() => import("screens/Home"))
const NavigationLayout = lazy(() => import("./NavigationLayout"))
const CallScreen = lazy(() => import("screens/Call/CallScreen"))
const Agents = lazy(() => import("screens/Agents"))

const withSuspense = (Component) => {
  return (
    <Suspense fallback={<div></div>}>
      <Component />
    </Suspense>
  )
}

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/help" element={withSuspense(Help)} />
      <Route path="/signIn" element={withSuspense(SignIn)}>
        <Route index element={withSuspense(LoginForm)} />
      </Route>
      <Route path="/download" element={withSuspense(SignIn)}>
        <Route index element={withSuspense(Download)} />
      </Route>
      <Route path="/" element={withSuspense(NavigationLayout)}>
        <Route index element={withSuspense(Home)} />
        <Route path="/settings" element={withSuspense(SettingsNav)}>
          <Route index element={withSuspense(Settings)} />
          <Route path="/settings/my-info" element={withSuspense(SettingsMyInfo)} />
          <Route path="/settings/device-test" element={withSuspense(SettingsDeviceTest)} />
          <Route path="/settings/feedback" element={withSuspense(SettingsFeedback)} />
        </Route>
        <Route path="/current-call" element={withSuspense(CallScreen)} />
        <Route path="/agents" element={withSuspense(Agents)} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  )
}

export default MainRouter
