import React from "react"
import cs from "classnames"

const Help = () => {
  return (
    <div data-testid="help-page" className={cs("max-w-3xl mx-auto text-left my-9")}>
      <h2 className={cs("text-2xl font-bold mb-2")}>How Tos</h2>
      <p className={cs("text-gray-700 italic mb-6")}>
        If you are unable to sign in or experience any issues at any time, please contact your IT department.
      </p>

      <h3 className={cs("text-xl font-semibold mt-6 mb-3 border-b pb-3text-xl font-semibold mt-6 mb-3 border-b pb-3")}>
        Sign in to your Organization
      </h3>

      <ol className={cs("list-decimal ml-5 space-y-4")}>
        <li>
          Open a web browser and enter your organization’s Okta address. For example:
          <pre className={cs("mt-2 text-gray-600 text-sm")}>http://mycompany.okta.com</pre>
        </li>
        <li>
          Enter your username and password, and then select <strong>Sign-in</strong>. Enter your alias in the username
          field. For example, if your username is <strong>jsmith@mycompany.com</strong>, just enter{" "}
          <strong>jsmith</strong>.
          <p className="mt-2">
            After you enter your username, your security image is displayed automatically if you have previously
            completed a successful logon in the browser you are using. This feature requires browser cookies.
          </p>
          <p className={cs("mt-3")}>
            <strong>Caution:</strong> If you have successfully signed in on the current browser before and have not
            cleared cookies, do not enter your password if your security image does not display when you enter your
            username.
          </p>
          <p className="mt-2">
            If your security image does not appear, close the browser and confirm that you are using the correct address
            to sign in for your organization. Then, open a new browser window, type the address in manually, and enter
            your username again. If your security image is still not displayed, report the issue using the{" "}
            <strong>Send Feedback</strong> link.
          </p>
          <p className="mt-2">
            If you see the error message <span className="tracking-wider	text-gray-600">Sign in failed!</span> your
            username and password do not match those specified for your profile, or you do not have access permission.
            Please contact your system administrator.
          </p>
        </li>
      </ol>

      <h3 className={cs("text-xl font-semibold mt-6 mb-3 border-b pb-3")}>Report a Security Issue</h3>
      <ol className={cs("list-decimal ml-5 space-y-4")}>
        <li>
          Under More Help, click the <strong>Send Feedback</strong> link.
        </li>
        <li>
          Enter your email address, and from the <strong>What do you need help with?</strong> drop-down, select{" "}
          <strong>Report a potential security issue</strong>.
        </li>
        <li>
          Enter a message, and click <strong>Send Message</strong>.
        </li>
      </ol>
    </div>
  )
}

export default Help
